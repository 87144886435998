import { Card, Row, Col, Form, Checkbox, Input, DatePicker } from "antd";
import { useState } from "react";

const NARRATIVE = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="horizontal"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Form.Item name="Documentation/Notes:" label="Documentation/Notes:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Periodic Report procedures used to establish new certification period."
      >
        <Checkbox value="Periodic Report procedures used to establish new certification period.">
          Periodic Report procedures used to establish new certification period.
        </Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Verbal explanation of work requirements offered to client unsuccessful."
      >
        <Checkbox value="Verbal explanation of work requirements offered to client unsuccessful.">
          Verbal explanation of work requirements offered to client
          unsuccessful.
        </Checkbox>
      </Form.Item>
      <Form.Item name="Worker Name:" label="Worker Name:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Row>
        <label>
          BE SURE ALL TASKS ASSOCIATED WITH THE CASE HAVE BEEN CLAIMED, WORKED,
          AND COMPLETED.
        </label>
      </Row>
    </Form>
  );
};
export default NARRATIVE;

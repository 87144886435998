import { Card, Row, Col, Space, Badge, Image, Select } from "antd";
import { useState } from "react";
import lLogo from "../images/im1.png";
import lLogo2 from "../images/img2.png";
import BPMForm from "./BPM";
const ScriptsForm = (props) => {
  const [textValue, setTextValue] = useState("");
  const handleChange = (value) => {
    // console.log(`selected ${value}`);
    setTextValue(value);
  };
  return (
    <div style={{ maxHeight: "500px", overflow: "auto" }}>
      <Space wrap>
        <Select
          defaultValue="Scripts"
          style={{
            width: 270,
          }}
          onChange={handleChange}
          options={[
            {
              value: "Scripts",
              label: "Scripts",
            },
            {
              value: "BPM",
              label: "BPM",
            },
          ]}
        />
      </Space>
      {textValue === "Scripts" || textValue === "" ? (
        <Card
          title="HSA Greater Script"
          hoverable
          style={{ marginTop: "10px" }}
        >
          <Row>
            <Row>
              <Space direction="vertical">
                <p>
                  EES has three avenues of service: Lobby, Non-Lobby and Phones
                  (Virtual Contact Center (VCC). This tool should be used when
                  greeting and interacting with clients in the Service Center
                  (lobby) and/or the VCC.
                </p>
                <p>
                  <strong>Greet the person:</strong> Good Morning/Afternoon! How
                  can I help you today?
                </p>
                <ul style={{ listStyletype: "square" }}>
                  <li>
                    <strong>Determine</strong> if the client needs translation
                    services.
                  </li>
                  <ul style={{ listStyletype: "circle" }}>
                    <li>
                      For translation services, follow the Interpreter Process
                      for your region.
                    </li>
                  </ul>
                  <li>
                    <strong>Determine</strong> if the client is here to see EES
                    or a non-EES partner.
                  </li>
                  <ul style={{ listStyletype: "circle" }}>
                    <li>
                      {" "}
                      For non-EES requests, including Employment Services, Adult
                      Protective Services (APS), Prevention and Protection
                      Services (PPS), Vocational Rehabilitation (VR), Kansas
                      Department of Health and Environment (KDHE) and Child
                      Support Services (CSS) route according to standard office
                      procedures. For employment services follow local office
                      procedures to connect with the Career Navigator.{" "}
                    </li>
                    <li> For EES Clients, follow the process below.</li>
                  </ul>
                </ul>
                <u>
                  <strong>Process for EES Clients:</strong>
                </u>
                <ol>
                  <li>
                    {" "}
                    For in-person clients, have the client enter their social
                    security number into the keypad to maintain confidentiality.
                  </li>
                  <li>
                    {" "}
                    <strong>Complete a Person Search</strong> in KEES using the
                    client’s SSN or other available Personal Identifying
                    Information (PII). For detailed instructions see{" "}
                    {
                      <a
                        href="https://dcfnet.dcf.ks.gov/EES/Documents/KEESP3TrainingResources/Person Search Inquiry K2K.pdf"
                        target="_blank"
                      >
                        Person Search instructions
                      </a>
                    }
                  </li>
                  <br />
                  <Image
                    className="img"
                    preview={false}
                    width={330}
                    height={120}
                    src={lLogo}
                    alt="DCF Banner"
                  />

                  <p></p>
                  <li>
                    {" "}
                    <strong>Verify</strong> the correct case is selected from
                    all associated non-medical cases.
                    <ol type="a">
                      <li>
                        {" "}
                        To identify the correct case, ask clarifying questions
                        to the client.
                        <ol type="i">
                          <li> Are you getting benefits now?</li>
                          <li> Are you here to apply for benefits?</li>
                          <li>Who is part of your household?</li>
                        </ol>
                      </li>
                      Keep in mind, these are not all inclusive, there may be
                      other questions you will need to ask to determine the
                      correct case.
                      <li>
                        {" "}
                        Staff will review information in KEES and select the
                        last case the client was associated to or is currently
                        active on based on clarifying responses provided by the
                        client.
                      </li>
                    </ol>
                  </li>
                  <p></p>
                  <li>
                    <strong>Verify</strong> client’s Name and Date of Birth on
                    the KEES Case Summary Page.
                    <ol type="a">
                      <li>
                        The client must be verified they are who they say they
                        are before providing case specific information.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Triage:</strong>
                    <ol type="a">
                      <li>
                        {" "}
                        Determine why the client is contacting the agency and
                        use KEES and Perceptive Content to determine which
                        functional team in the EES Tracker they will be added
                        to, according to the hierarchy below (from left to
                        right):
                      </li>
                      <Image
                        className="img"
                        preview={false}
                        width={350}
                        src={lLogo2}
                        alt="DCF Banner"
                      />
                    </ol>
                  </li>
                </ol>
                <br />
                <u>
                  <strong>For In-Person Clients:</strong>
                </u>
                <ol>
                  <li>
                    <strong>If Lobby is Full Service, ask and inform</strong>{" "}
                    the client:
                    <ol type="a">
                      <li>
                        {" "}
                        Are you able to wait and speak with someone who can help
                        you? If you’re able to wait and speak with someone,
                        they’ll be able to help you, and you may leave knowing
                        what you’re eligible for or at least know what is still
                        needed and what needs to happen next. If you are able to
                        wait, please understand you will likely not be seen in
                        the order you came in, as others are waiting to speak to
                        someone for different reasons. Can you stay and speak
                        with a worker?
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>If Yes:</strong>
                    <ol type="a">
                      <li>
                        <strong>Search</strong> the EES Tracker to determine if
                        a client request already exists and:
                        <ol type="i">
                          <li>
                            {" "}
                            If a client request is already in the EES tracker
                            for the Non-Lobby, on the client request change the
                            location from Non-Lobby to Lobby and update the date
                            and time following the steps in the{" "}
                            {
                              <a
                                href="https://dcfnet.dcf.ks.gov/EES/Documents/BPM/EES Tracker User Guide.pdf"
                                target="_blank"
                              >
                                <u>EES Tracker User Guide.</u>
                              </a>
                            }
                            Update the standard EES Tracker comments if needed
                            using the{" "}
                            {
                              <a
                                href="https://dcfnet.dcf.ks.gov/EES/Documents/BPM/Standard EES Tracker Comments.pdf"
                                target="_blank"
                              >
                                <u>Standard EES Tracker Guide Comments.</u>
                              </a>
                            }
                          </li>
                          <li>
                            If a client request does not exist on the EES
                            Tracker, use the{" "}
                            {
                              <a
                                href="https://dcfnet.dcf.ks.gov/EES/Documents/BPM/EES Tracker User Guide.pdf"
                                target="_blank"
                              >
                                <u>EES Tracker User Guide </u>
                              </a>
                            }
                            to add a new request with the location of Lobby.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Add the client request to the EES Tracker, location of
                        Lobby.{" "}
                      </li>
                      <li>
                        <strong>Inform</strong> the client to have a seat and
                        someone will be calling them.
                      </li>
                      <strong>Note:</strong> For offices providing Virtual Lobby
                      services, provide instructions to the client regarding how
                      they will be contacted.
                    </ol>
                  </li>
                  <li>
                    <strong>If no or the Lobby is at Basic Service: </strong>
                    <ol type="a">
                      <li>
                        <strong>Obtain</strong> the clients information and
                        documents provided.
                      </li>
                    </ol>
                  </li>
                </ol>
              </Space>
            </Row>
          </Row>
        </Card>
      ) : (
        <BPMForm />
      )}
    </div>
  );
};
export default ScriptsForm;

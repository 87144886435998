import { Card, Row, Col, Space,Image } from "antd";
import { useState } from "react";
import lLogo3 from '../images/img3.png';
import lLogo4 from '../images/img4.png';
import lLogo5 from '../images/img5.png';
import lLogo6 from '../images/img6.png';
import lLogo7 from '../images/img7.png';
import lLogo8 from '../images/img8.png';
import lLogo9 from '../images/img9.png';
import lLogo10 from '../images/img10.png';
import lLogo11 from '../images/img11.png';
import lLogo12 from '../images/img12.png';
import lLogo13 from '../images/img13.png';
import { Center } from "devextreme-react/map";

const BPMForm = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
      <div style={{ maxHeight: "500px", overflow: "auto" }}>
        <Card title="" hoverable>
          <Row>
            <Row>
            <Space direction="vertical">
            <p>The BPM Scripts and Documentation Templates are a tool maintained by the BPM team. They are required for use while processing ALL Applications and Reviews.</p>
            <p>The BPM Scripts and Documentation Templates are designed to ensure eligibility workers conduct a 
            focused eligibility determination by asking or addressing only questions relevant to the program(s) applied for and/or renewing and will help navigate through the KEES system by keying as you go therefore reducing rework by eliminating double entries between the template and KEES. Simultaneous processing of conducting the case action(s), keying in KEES as you go, and keeping the client with you for the duration of the interaction significantly increases capacity and reduces errors. The Team Interview Scripts and Documentation Templates also serve as the record of documentation (KEESM 1400 and subsections),
            capturing the circumstances and reasoning of the eligibility determination and actions taken.</p>
          <p>Follow the BPM Scripts and Documentation Templates from top to bottom.  Use prudent person ({<a href="https://content.dcf.ks.gov/EES/KEESM/Current/keesm1300.htm">KEESM 1310</a>}) to obtain further information by asking confirming questions to validate information that the client has disclosed before you have reached the appropriate section of the interview. Do not jump back and forth to capture information that the client discusses outside the normal flow of the interview. </p>
      
          <p>The BPM Scripts and Documentation Templates are not designed to detail every question you will need to ask to complete the case in KEES. The BPM Scripts and Documentation Templates only ensure that eligibility workers ask and/or address questions necessary to make an eligibility determination based on the program(s) being applied for or renewing. You will need to navigate to KEES detail screens and ask appropriate questions to complete these screens.  The BPM Manual details the intended client, purpose, and reason for the Template:</p>
          
          <ul class="b">
              <li><strong>BPM Script and Documentation Templates:</strong></li>
              <p><ul class="a">
              <li><strong>User:</strong>&ensp;All EES Staff and anyone reading the case.  Eligibility Workers will use the BPM Script and Documentation Template(s)/Template(s) to ensure an efficient, accurate and focused interview is conducted and case action is documented consistently.  Use of the BPM Script and Documentation Template will encourage an environment of trust for Eligibility Staff that must finish processing the case.  Clients benefit from the BPM Scripts and Documentation Templates by only being asked questions that are relevant to the programs they are applying for. </li>
             <li><strong>Purpose:</strong>&ensp;Using the BPM Script and Documentation Template will provide a format of consistency and trust throughout the Application or Review process for all programs regardless of interview requirements.  Proper use of the BPM Script and Documentation Template ensure Eligibility Workers ask or address only the questions necessary to determine eligibility based on the program(s) a person has applied for or is renewing at the initial touch.  The Template provides a platform for standard, consistent documentation.  This ensures that Eligibility Staff can trust the information documented on the BPM Script and Documentation Template when they finish processing the case and make an eligibility determination. In turn reducing rework. </li>
             <li><strong>Why use them?</strong>&ensp;The BPM Script and Documentation Template(s) are required to be used by all Eligibility Staff for Applications and Reviews 
             received regardless of interview requirements.   
             Each BPM Script and Documentation Template asks only the questions that are
              relevant to the programs applied for.  The “BPM Script and Documentation Template(s)”
               were designed to flow with KEES as much as possible. This will allow Eligibility Staff 
               to follow the process by keying KEES as they go, for programs requiring an interview. 
               The BPM Script and Documentation Template(s) should be utilized to explain to the client 
               the outcome of the initial eligibility determination or the interview.  
               This will reduce unnecessary repeat visits. While the <strong>“Who, What, When and Where”</strong> information is keyed into KEES, the <strong>“Why”</strong> of the circumstances and reasoning of the workers actions should be documented on the template.  This minimizes re-work for the Eligibility Staff that finishes the case action as well as reduces duplication between the template and KEES.  The simultaneous process of conducting the interview, "keying as you go", and keeping the client present for the duration of the interview significantly increases capacity and reduces errors caused by duplication.</li>
         </ul></p>
          </ul>
          <h2><strong><u>Proper Use of the BPM Script and Documentation Template</u></strong></h2>
          <p>The Template contains several places where interviewers are required to input information.</p>
          <Image className="img"
          preview={false} width={400}  src={lLogo3}
          alt="DCF Banner"
         />
         <br/>
         <Image className="img"
         preview={false} width={400}  src={lLogo4}
         alt="DCF Banner"
        />
        <p></p>
        <u><strong>BPM Script and Documentation Template </strong></u>
        <h5 style={{textAlign: 'center', fontSize:'medium'}}><u>HEADER</u></h5>
        <ul style={{listStyletype:'circle'}}>
        <li>A new BPM Script and Documentation Template must be used for each Application (ES-3100 or ES-3100S)
         or Review (ES-3100.6), even if no show occurs. 
         Begin processing the Application or Review (
          by opening a new BPM Script and Documentation Template
           for the corresponding team. The “Start Time” section of the BPM Script
            and Documentation Template automatically populates the date and time that
             the document was opened. 
             <ul style={{listStyletype:'circle'}}>
             <li>This provides case documentation ({<a href="https://content.dcf.ks.gov/EES/KEESM/Current/keesm1213.htm" target="_blank">KEESM</a>} 1213.10) of the actions taken at the time of the action. </li>
             </ul></li>
             <li>The BPM Script and Documentation Template also contains boxes
              reminding you to claim all tasks in KEES associated to the Application or Review.
               Check Perceptive Content for information, verifications, and supporting documents related to 
             the Application or Review, and to ensure the Application or Review is complete.</li>
        </ul>
        <Image className="img"
        preview={false} width={400}  src={lLogo5} style={{display : 'block', marginLeft: '8%',
        }}
        alt="DCF Banner"
       />
       <h5 style={{textAlign: 'center', fontSize:'medium'}}><u>REGISTRATION</u></h5>
       <p>At this point the Application or Review should be registered; however, you will NOT be keying as you go yet.
        The BPM Script and Documentation Template and the KEES system are meant to work together and to
         follow the flow of one another once the interview begins. However, there are screening tools
          included that do not follow the flow. There are documentation and screening requirements
           specific to Food Assistance, TANF, and Child Care Applications and Reviews that must be
            documented (KEESM {<a href="https://content.dcf.ks.gov/EES/KEESM/Current/keesm1415.htm" target="_blank">1415.1</a>}, {<a href="https://content.dcf.ks.gov/EES/KEESM/Current/keesm1412.htm" target="_blank">1412.3</a>}).</p>
            <p>You might find it helpful to preface this portion of the interview by explaining these questions are related to requirements specific to the program being applied for
             such as Expedited screening and compliance with Federal and State policies. Each question must be asked, and the answers noted.</p>
             <Image className="img"
             preview={false} width={400}  src={lLogo6} style={{display : 'block', marginLeft: '8%',
             }}
             alt="DCF Banner"
            />
            <h5 style={{textAlign: 'center', fontSize:'medium'}}><u>SCREENING</u></h5>
            <p>The screening section is designed to inform the client of requirements
             specific for each program ({<a href="https://content.dcf.ks.gov/EES/KEESM/Current/keesm2100.htm" target="_blank"><u>KEESM 2000</u></a>} and subsections).</p>
             <p>Under the program column are titles alerting you to which program requires which sections.
              Complete the sections for the applicable programs and leave
               sections for programs not included on the case blank.</p>
               <Image className="img"
               preview={false} width={400}  src={lLogo7} style={{display : 'block', marginLeft: '8%',
               }}
               alt="DCF Banner"
              />
              <Image className="img"
              preview={false} width={400}  src={lLogo8} style={{display : 'block', marginLeft: '8%',
              }}
              alt="DCF Banner"
             />
             <h5 style={{textAlign: 'center', fontSize:'medium'}}><u>SYSTEM ENTRY</u></h5>
             <p>The System Entry section is where you are expected to begin the key-as-you-go practice.
             </p>
             <p>Each section relates to a screen within KEES identified in the Page Name column. </p>
             <p><strong>Use comment boxes in each section to document clarified information, verifications, 
             collateral contacts, system checks, interface checks, and case actions. Capture all of this 
             to serve as the record of documentation. Include the who (the name and contact information, system, etc.)
              any calculation taken, action taken, decision made, KEESM references used and NOA(s) sent.</strong></p>
              <p>When a case is closed the data for an individual is not closed with it. It is possible that historical 
              data retained could impact current eligibility. (Household composition, Income, and Expenses are examples)</p>
              <p>Be sure to document any discrepancies found during system updates. DO NOT edit current address- ALWAYS add new.
              </p>
              <p>Make every effort to follow the flow of the template, allowing it to guide you through KEES.</p>
              <p>Some sections will contain bulleted items, these are questions you should ask the client to 
              get information relevant to the section of KEES.
               They will likely require follow up and may lead you to additional KEES detail pages.</p>
               <Image className="img"
              preview={false} width={400}  src={lLogo9} style={{display : 'block', marginLeft: '8%',
              }}
              alt="DCF Banner"
             />
             <p><strong>Note: Be sure to ask any follow up questions needed to clarify information as needed.</strong></p>
             <p>Some questions will need to be asked for each household member separately. Because the information
              is captured by a separate page for each member, trying to ask these questions 
             for all members at once is error prone. These Pages include: individual demographics, citizenship/Identity list, etc.</p>
             <Image className="img"
             preview={false} width={400} src={lLogo10} style={{display : 'block', marginLeft: '8%',
             }}
             alt="DCF Banner"
            />
            <h5 style={{textAlign: 'center', fontSize:'medium'}}><u>INFORMING CLIENTS</u></h5>
            <p>The Informing Clients section of the Template provides a place to verify and document that required communications between the agency and the client have occurred. </p>
            <p>(KEESM {<a href="https://content.dcf.ks.gov/EES/KEESM/Current/keesm1411.htm" target="_blank"><u>1400</u></a>}, {<a href="https://content.dcf.ks.gov/EES/KEESM/Current/keesm1710.htm" target="_blank"><u>1700</u></a>}, {<a href="https://content.dcf.ks.gov/EES/KEESM/Current/keesm9330.htm" target="_blank"><u>9330</u></a>} and subsections) </p>
            <p>Each section must be addressed verbally with the client and the response captured.</p>
            <Image className="img"
            preview={false} width={400} src={lLogo11} style={{display : 'block', marginLeft: '8%',
            }}
            alt="DCF Banner"
           />
           <h5 style={{textAlign: 'center', fontSize:'medium'}}><u>VERIFICATION LIST AND RUN EDBC</u></h5>
           <p>The Verification List section of the Template contains a check box to indicate that you
            have sent a request for information. It also contains a list of required communications that you must complete during an interview. 
           </p>
           <p>The Run EDBC section also contains a section of required actions that must be completed when
            finishing the eligibility action(s) whether an interview was required or not. There are no checkboxes,
             but it is still your responsibility to ensure all the actions have occurred.</p>
           <p>When pending a case, run EDBC and review that all data is entered and correct. DO NOT ACCEPT RESULTS!!!</p>
           <Image className="img"
           preview={false} width={400} src={lLogo12} style={{display : 'block', marginLeft: '8%',
           }}
           alt="DCF Banner"
          />
          <h5 style={{textAlign: 'center', fontSize:'medium'}}><u>NARRATIVE</u></h5>
          <p>The Narrative section of the Template is for use in documenting relevant information
           and important actions taken that are not captured in the BPM Script and Documentation Template, Application,
            the Review KEES, Perceptive Content, or anywhere else required.
           Document final outcome of the case including what the next worker needs to know if an eligibility determination cannot be made.</p>
           <Image className="img"
           preview={false} width={400} src={lLogo13} style={{display : 'block', marginLeft: '8%',
           }}
           alt="DCF Banner"
          />
            </Space>
          </Row>
        </Row>
      </Card>
    </div>
  );
};
export default BPMForm;

import { Card, Row, Col, Form, Checkbox, Input, DatePicker } from "antd";
import { useState } from "react";

const CC_TANF = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Form.Item
        valuePropName="checked"
        name="ES-3102 given to and discussed with applicant ES-3102  ES-3102S  (interviewed)"
      >
        <Checkbox
          value="ES-3102 given to and discussed with applicant ES-3102  ES-3102S  (interviewed)
"
        >
          ES-3102 given to and discussed with applicant{" "}
          {
            <a
              style={{ DisplayFormat: "inline-block" }}
              href="http://content.dcf.ks.gov/ees/KEESM/Forms/ES-3102_ImportantInformationAboutCooperationWithChild"
              target="_blank"
            >
              {" "}
              ES-3102
            </a>
          }
          &ensp;
          {
            <a
              style={{ DisplayFormat: "inline-block" }}
              href="http://content.dcf.ks.gov/ees/KEESM/Forms/ES-3102S_ImportantInformationAboutCooperationWithChild Support10-21.pdf"
              target="_blank"
            >
              ES-3102S
            </a>
          }
          &ensp;(interviewed)
        </Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="ES-3102 mailed to applicant (No Interview)"
      >
        <Checkbox
          value="ES-3102 mailed to applicant (No Interview)
"
        >
          ES-3102 mailed to applicant (No Interview)
        </Checkbox>
      </Form.Item>
    </Form>
  );
};
export default CC_TANF;

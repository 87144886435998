import { Card, Row, Col, Form, Checkbox, Input, DatePicker } from "antd";
import { useState } from "react";

const CCO = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Form.Item
        valuePropName="checked"
        name="Are all adults who are employed working at least an average of 20 hours per week earning at least the federal minimum wage per hour?"
      >
        <Checkbox
          value="Are all adults who are employed working at least an average of 20 hours per week earning at least the federal minimum wage per hour?
"
        >
          Are all adults who are employed working at least an average of 20
          hours per week earning at least the federal minimum wage per hour?
        </Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Are you requesting childcare to complete a degree or certificate program within 24 months with the goal of employment in a related field of study & employed a minimum of 15 hours per week earning at least the federal minimum wage per hour?"
      >
        <Checkbox
          value="Are you requesting childcare to complete a degree or certificate program within 24 months with the goal of employment in a related field of study & employed a minimum of 15 hours per week earning at least the federal minimum wage per hour?
"
        >
          Are all adults who are employed working at least an average of 20
          hours per week earning at least the federal minimum wage per hour?
        </Checkbox>
      </Form.Item>
      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending for:" label="Pending for:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default CCO;

import { Row, Col, Form, Input } from "antd";
const RETRIEVECONTACT = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Form.Item name="Name" label="Name">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Phone Number" label="Phone Number">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default RETRIEVECONTACT;

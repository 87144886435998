import { Card, Row, Col, Form, Checkbox, Input, DatePicker } from "antd";
import { useState } from "react";

const ScreenName_PW = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <label>Request to withdraw application. Programs withdrawn:</label>
      </Row>

      <Row gutter={2}>
        <Col span={8}>
          <Form.Item
            valuePropName="checked"
            name="Request to withdraw application. Programs withdrawn - FA"
          >
            <Checkbox value="Request to withdraw application. Programs withdrawn - FA">
              FA
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            valuePropName="checked"
            name="Request to withdraw application. Programs withdrawn - TANF"
          >
            <Checkbox value="Request to withdraw application. Programs withdrawn - TANF">
              TANF
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            valuePropName="checked"
            name="Request to withdraw application. Programs withdrawn - CC"
          >
            <Checkbox value="Request to withdraw application. Programs withdrawn - CC">
              CC
            </Checkbox>
          </Form.Item>
        </Col>
        <Form.Item name="Comments" label="Comments">
          <Input style={{ width: "100%" }} />
        </Form.Item>
      </Row>
    </Form>
  );
};
export default ScreenName_PW;

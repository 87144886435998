import { Row, Col, Form, Checkbox, Input, Space, Badge, Radio } from "antd";
import { useState } from "react";

const AnswerCall = (props) => {
  const [agentName, setAgentName] = useState(props.agentName);
  const [callbackNumber, setCallbackNumber] = useState(props.callbackNumber);

  const onChangeName = (e) => {
    setAgentName(e.target.value)
    props.form.setFieldsValue({
      'Agent Name': e.target.value,
    });
  }

  const onChangeCallbackNumber = (e) => {
    setCallbackNumber(e.target.value)
    props.form.setFieldsValue({
      'Callback Number': e.target.value,
    });
  }

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row gutter={[2, 2]}>
        <Col span={4}>Start Time</Col>
        <Col span={20}>{props.callAnswered}</Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>ANSWER CALL:</Row>
      <Row>
        <Row>
          <Space direction="vertical">
            <Badge
              color="#0d0b0b"
              text="Thank you for calling the Kansas Department for Children and
              Families. My Name is:"
            />
          </Space>
          <Space
            direction="vertical"
            style={{
              width: "100%",
            }}
          >
            <Form.Item
              name="Agent Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input id="agent_name" value={agentName} onChange={onChangeName} placeholder={agentName || "Enter Agent Name"} />
            </Form.Item>
          </Space>
          <Space direction="vertical">
            <Badge
              color="#0d0b0b"
              text="Can I please get your name and a good call back number in case
              we get disconnected?"
            />
          </Space>
          {/* <Space direction="vertical">
            <Badge color="#0d0b0b" text="Client Call back Number:" />
          </Space> */}
          <Space
            direction="vertical"
            style={{
              width: "100%",
            }}
          >
            <Row gutter={2}>
              <Col span={12}>
                <Form.Item
                  name="Client Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input id="clientName" placeholder="Enter Client Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="Callback Number"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input id="callbackNum" value={callbackNumber} onChange={onChangeCallbackNumber} placeholder={callbackNumber || "Enter Callback Number"} />
                </Form.Item>
              </Col>
            </Row>
          </Space>
          <Space direction="vertical">
            <Badge
              color="#0d0b0b"
              text="If our call is disconnected, at any point, I will call you back
              at the number you have provided. Please do not attempt to call the
              main number again as that will place you back in the queue and you
              will need to start your wait time and interview over."
            />
          </Space>
          <Space direction="vertical">
            <Badge
              color="#0d0b0b"
              text="Completing an interview is required for both the Food Assistance
              and TANF programs."
            />
          </Space>
          <Space direction="vertical">
            <Badge
              color="#0d0b0b"
              text="At the end of your interview I will inform you of your
              eligibility determination or what information is still needed to
              make a determination."
            />
          </Space>
          <Space direction="vertical">
            <Badge
              color="#0d0b0b"
              text="The typical interview takes around (your average time to
              complete interview)."
            />
          </Space>
          <Space direction="vertical">
            <Badge
              color="#0d0b0b"
              text="Do you have time to complete the entire interview process today?"
            />
          </Space>
        </Row>
      </Row>
      <Form.Item
        name="Do you have time to complete the entire interview process today?"
        // rules={[
        //   {
        //     required: true,
        //   },
        // ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>

      <Row style={{ marginTop: "10px" }}> TASK/Flags:</Row>
      <Form.Item
        name="Task Flags"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox.Group
          style={{
            width: "100%",
            marginTop: "10px",
          }}
          //   onChange={onChange}
        >
          <Row>
            <Col span={12}>
              <Checkbox value="Claim All KEES Tasks (Do not claim WP task)">
                Claim All KEES Tasks (Do not claim WP task)
              </Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value="Claim case from EES Tracker">
                Claim case from EES Tracker
              </Checkbox>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Checkbox value="Review Case Flags">Review Case Flags</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value="Review KEES Journal">
                Review KEES Journal
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Row gutter={[2, 2]}>
        <Col span={4}>Perceptive Content</Col>
        <Col span={20}>
          Check OneNote / Perceptive Content for verification(s) provided
        </Col>
      </Row>
      <Row gutter={[2, 2]} style={{ marginTop: "5px" }}>
        <Col span={4}>Application</Col>
        <Col span={20}>
          <Row>
            Check to make sure the application is complete (Signature, Date, and
            Address). If relevant questions are not answered, the missing
            information can be obtained during the interview. If on the phone
            ensure the application has been signed, if not conduct the
            Telephonic Signature process.
          </Row>
          <Row>
            ***Check All Program Blocks for requested programs and HH members***
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default AnswerCall;

import { Row, Col, Form, Checkbox, Input, Space, Badge, Radio } from "antd";

const Registration = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      // labelCol={{
      //   flex: "200px",
      // }}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Form.Item
        name="Type of Registration"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox.Group
          style={{
            width: "100%",
          }}
        >
          <Row>
            <Col span={6}>
              <Checkbox value="Application">Application</Checkbox>
            </Col>

            <Col span={4}>
              <Checkbox value="Review">Review</Checkbox>
            </Col>
            <Col span={4}>
              <Checkbox value="FA">FA</Checkbox>
            </Col>
            <Col span={4}>
              <Checkbox value="TANF">TANF</Checkbox>
            </Col>
            <Col span={4}>
              <Checkbox value="CC">CC</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="E-Application (accept or reject data for all e-apps)">
                E-Application (accept or reject data for all e-apps)
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item
        name="Case Name"
        label="Case Name"
        rules={[
          {
            required: true,
            message: "Enter Case Name",
          },
        ]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="KEES Case Number"
        label="KEES Case #"
        rules={[
          {
            required: true,
            message: "Enter KEES Case #",
          },
        ]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Interviewee Name (Not PI)"
        label="Interviewee Name (Not PI)"
        rules={[
          {
            required: true,
            message: "Enter Interviewee Name",
          },
        ]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Mode of Communication:"
        // label=""
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox.Group
          style={{
            width: "100%",
          }}
          //   onChange={onChange}
        >
          <Row>
            <Checkbox value="Face to Face">Face to Face</Checkbox>
            <Checkbox value="Phone">Phone</Checkbox>
            <Checkbox value="No Interview Required ">
              No Interview Required
            </Checkbox>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name="Will your Household’s gross income for the month be less than
              $150?"
        label="Will your Household’s gross income for the month be less than
              $150?"
        rules={[
          {
            required: true,
          },
        ]}
        style={{ marginTop: "10px" }}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Does your household have less than $100 in cash, checking and savings?"
        label="Does your household have less than $100 in cash, checking and savings?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <label>If #1 and #2 are yes, household is expedited.</label>
      <Form.Item
        name="Is anyone in your household a seasonal farm worker?"
        label="Is anyone in your household a seasonal farm worker?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <label>If #3 and #2 are yes, household is expedited.</label>
      <Row style={{ marginTop: "15px" }}>
        <label>Ask below expense questions:</label>
      </Row>
      <Form.Item
        name="Rent/Mortgage Expense"
        label="Rent/Mortgage Expense"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Monthly Utility Expense"
        label="Monthly Utility Expense"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Total Expense/s"
        label="Total Expense/s"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Expected Gross Income"
        label="Expected Gross Income"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Total Money in Cash, Checking & Savings"
        label="Total Money in Cash, Checking & Savings"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Total Income"
        label="Total Income"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="EXP>INC"
        label="EXP>INC"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <label>If shelter expenses exceed income, household is expedited.</label>
      <Form.Item
        valuePropName="checked"
        name="Case is Expedited (Indicate on New/Reapplication Detail page)"
        // label="Case is Expedited (Indicate on New/Reapplication Detail page)"
      >
        <Checkbox value="Case is Expedited (Indicate on New/Reapplication Detail page)">
          Case is Expedited (Indicate on New/Reapplication Detail page)
        </Checkbox>
      </Form.Item>
      <Form.Item name="Expense - Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Is anyone in your household fleeing felony prosecution or jail?"
        label="Is anyone in your household fleeing felony prosecution or jail?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Is anyone in your household fleeing felony prosecution or jail? - Name:"
        label="Name"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Is anyone in your household in violation of probation or parole?"
        label="Is anyone in your household in violation of probation or parole?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Is anyone in your household in violation of probation or parole? - Name:"
        label="Name"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Has anyone in your household been convicted of trading food assistance benefits for drugs after September 22, 1996?"
        label="Has anyone in your household been convicted of trading food assistance benefits for drugs after September 22, 1996?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Has anyone in your household been convicted of buying or selling food assistance benefits over $500 after September 22, 1996?"
        label="Has anyone in your household been convicted of buying or selling food assistance benefits over $500 after September 22, 1996?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Has anyone in your household been convicted of buying or selling food assistance benefits over $500 after September 22, 1996? - Name:"
        label="Name"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Has anyone in your household been convicted of fraudulently getting duplicate food assistance benefits in any state after September 22, 1996?"
        label="Has anyone in your household been convicted of fraudulently getting duplicate food assistance benefits in any state after September 22, 1996?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Has anyone in your household been convicted of fraudulently getting duplicate food assistance benefits in any state after September 22, 1996? - Name:"
        label="Name"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Has anyone in your household been convicted of trading food assistance benefits for guns, ammunitions or explosives after September 22, 1996?"
        label="Has anyone in your household been convicted of trading food assistance benefits for guns, ammunitions or explosives after September 22, 1996?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Has anyone in your household been convicted of trading food assistance benefits for guns, ammunitions or explosives after September 22, 1996? - Name:"
        label="Name"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Does anyone in your household have a felony drug related conviction on or after August 22, 1996?"
        label="Does anyone in your household have a felony drug related conviction on or after August 22, 1996? "
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Does anyone in your household have a felony drug related conviction on or after August 22, 1996? - Name:"
        label="Name"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Row>
        <label>
          Have you or any other adult in your household been convicted of one or
          more of the following crimes after February 7, 2014
        </label>
      </Row>
      <Row>
        <Space direction="vertical">
          <Badge color="#0d0b0b" text="Aggravated sexual abuse" />
        </Space>
      </Row>
      <Row>
        <Space direction="vertical">
          <Badge color="#0d0b0b" text="Murder" />
        </Space>
      </Row>
      <Row>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Sexual exploitation and other abuse of children or "
          />
        </Space>
      </Row>
      {/* <Row>
          <Space direction="vertical">
            <Badge color="#0d0b0b" text="Sexual assault?" />
          </Space>
        </Row> */}
      <Form.Item
        name="Sexual assault?"
        label={
          <Space direction="vertical">
            <Badge color="#0d0b0b" text="Sexual assault?" />
          </Space>
        }
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Have you or any other adult in your household been convicted of one or
          more of the following crimes after February 7, 2014 - Name:"
        label="Name"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="If yes, are you in compliance with the terms of your sentence? "
        label="If yes, are you in compliance with the terms of your sentence? "
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="Registration - Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default Registration;
